import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Aries Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <br />
             Brave and enterprising, the Aries zodiac sign is the first sign of the zodiac. These audacious Rams are vibrant, motivated, and assured authorities. Arians are a bundle of vibrancy and energy. The people born with the star sign Aries live on their terms, often unintentionally compromising their ideas, objectives, and beliefs. This sign is a mark of the virtue and narcissism of a newborn.
 
 <br/><br/>
 Whether you accept it or not, everyone has good/positive and bad/negative traits, and the Aries zodiac is no different. An individual born with Aries as their sun sign has some surprising character traits and negative ones. Let’s find and learn the Aries traits!

<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
Let’s look at some of the positive and negative traits of Aries
              </p> <br/>

<p className="font-bold text-orange-500 inline-block mr-2">
Positive Traits of Aries : 
              </p>Strong leadership, amazing self-confidence, positive energy, bravery, creativity<br/><br/>
              <p className="font-bold text-orange-500 inline-block mr-2">
Negative Traits of Aries : 
              </p> Selfishness, attention-seeking, outbursts of anger, lack Of patience, impulsive behavior<br></br>
<br/>
<p className="playfair text-black text-xl md:text-3xl">Aries Positive Traits</p><br/>
<p className="playfair text-black text-xl md:text-3xl">
Strong Leadership            
                </p><br/>
You are a natural-born director and know how to take charge of others. You possess a strong supervision trait that helps you be a winner in your undertakings. You succeed in the role of power. Aries are very enthusiastic in nature, and they don't wait for a possibility to knock on their door. You go out and make your fortune and fate.
 <br/><br/>

 <p className="playfair text-black text-xl md:text-3xl">
 Amazing Self Confidence      
                </p><br/>
Your self-confidence always boosts and motivates the people around you. Your personality isn't affected by adverse circumstances because this confidence persists in tight situations. Aries are influential, opinionated personalities who are highly independent creatures and have firm decisions about life. You always stick to your words; you're a man of your comments and opinions and seldom welcome suggestions from others. You prefer to be a frontiersperson and do things in your own way instead of following others. You love to walk on your own footprints; you believe in yourself and do something that you think they are right. 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
 Positive Energy    
                </p><br/>
Aries being a first zodiac sign, have a lot of optimistic energy. You are full of energy and vibrance because you belong to a fire sign ruled by Mars. You are energetic and hopeful in a tense situation. You would not like to sit and waste time cribbing about the case. Instead, you come up with an alternative strategy to be executed. You have no time to stay on your failures, defeats, and mistakes in unwanted conditions. Aries knows that sometimes bad things happen, and people face undesirable and unbearable problems. You have a powerful belief that posing and worrying will not switch your problematic situations. Instead, you concentrate on your dreams, goals, and aspirations and try to overpower hindrances coming on the path to success.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Bravery
                </p><br/>
Aries are solid, ultra-resilient, and one of the most courageous signs of the Zodiac. You have the guts to rise to the challenge and deal with the things head-on. You are not scared to take risks in life to get yourself to the top of the pack. You know how to stay brave in tight conditions and during life's downfalls.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
Creativity
                </p><br/>
Aries always investigate ways to do something different. Creative Aries means that residents can have difficulty coping with tedious and repetitious tasks. Just because you love to do creative things, You can not stand to do the same thing every single day. You would instead prefer creative assignments.

<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
Aries Negative Traits
                </p>
<br/>
<p className="playfair text-black text-xl md:text-3xl">Selfishness</p><br/>
One of the negative traits that Aries have is a consideration for self-advancement. When it comes to defeating the others, Aries can get up to anything. If you say you will do it, you will stick to it no matter what comes on your pathway. You would put additional effort into work, pull and push your potential, and secure energy to achieve your goals. You are at times cruel to the sentiments of others as you feel that you have more understanding and abilities than anyone around.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Attention Seeking
                </p><br/>
Aries people are attention seekers. They demand it. You would quickly get aggravated when you don't have it. You always want to be the center of attraction for everything and from everyone. You always want to do something better than others and do tasks efficiently. If you face imperfections and hurdles in functioning any job, you will not hold the hand of others. You would rather fight the problem and put your extra energy into accomplishing your goals efficiently and on time. You will use your talents and willingness to be a winner in all life situations. But sometimes, your connections get ignored, and your work-life harmony gets affected.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Outbursts of Anger
                </p><br/>
Aries is one of the short-tempered signs out there. It won't take much to irate you and generate an outburst of your anger. The most prominent problem of Aries is that you don't understand how to negotiate with their anger. You get outraged when things don't happen the way you want or if somebody finds fault in your work. You would never accept suggestions from others and never welcome the criticism of your way of work.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Lack Of Patience
                </p><br/>
Aries define their traits mostly in their impatient behavior. Patience doesn't come easily to you. The natives want everything when they desire it, without any delays. You are not able to keep calm and wait for the results. You quickly get bored with the boring things and switch to the other, rejecting them unfinished. You won't sit and examine the flaws in the way of success. You would achieve an alternative strategy without giving a dual thought to it.
 <br/><br/>
Arians have erratic behavior. You tend to make an instantaneous decision and don't waste time investigating it. You soon realize it wasn't the most acceptable option. Most of the time, you don't think of diverse aspects of the matter and don't assume the point of view of your teammates. You think of the results and conclusions.<br/><br/>
Investigating the circumstances of planets and planetary elements at the moment of your birth will give you a fantastic insight into your zodiac sign personality traits. It will reveal the purpose behind your sign. 
<p className="playfair text-black text-xl md:text-3xl">
Impulsive Behaviour
</p><br/>
Get your Horoscope for free, personalize your janampatri and get the most profound insights into your zodiac sign.
<br/><br/>
              
              
              
              <br>
              </br>
              <br />
              <p className="playfair text-black text-xl md:text-3xl"></p>
              <br />
     
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
